import axios from "axios";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
	Container,
	Typography,
	OutlinedInput,
	Button,
	Grid,
	Card,
	Box,
	useMediaQuery,
	useTheme,
	FormHelperText,
	Snackbar,
	Alert,
	CircularProgress,
} from "@mui/material";

export default function PostAdvertisement() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const response = await axios.post(
				"http://localhost:9090/local/advertisements",
				{
					title: data.title,
					description: data.description,
					javascript: data.javascript,
					advertiser: "6696df3255820b20d043b675",
				}
			);
			setSnackbarMessage("Advertisement posted successfully!");
			setSnackbarSeverity("success");
			reset();
			console.log(response.data);
		} catch (error) {
			setSnackbarMessage("Error posting advertisement. Please try again.");
			setSnackbarSeverity("error");
			console.error("Error posting advertisement:", error);
		} finally {
			setLoading(false);
			setSnackbarOpen(true);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<Container maxWidth="lg">
			<Typography variant="h4" gutterBottom>
				Add Advertisement
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid mt={10} container spacing={3}>
					<Grid item xs={12}>
						<Card>
							<Controller
								name="title"
								control={control}
								defaultValue=""
								rules={{ required: "Title is required" }}
								render={({ field }) => (
									<>
										<OutlinedInput
											{...field}
											placeholder="Title"
											fullWidth
											error={!!errors.title}
										/>
										{errors.title && (
											<FormHelperText error>
												{errors.title.message}
											</FormHelperText>
										)}
									</>
								)}
							/>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<Controller
								name="description"
								control={control}
								defaultValue=""
								rules={{ required: "Description is required" }}
								render={({ field }) => (
									<>
										<OutlinedInput
											{...field}
											placeholder="Description"
											fullWidth
											multiline
											rows={4}
											error={!!errors.description}
										/>
										{errors.description && (
											<FormHelperText error>
												{errors.description.message}
											</FormHelperText>
										)}
									</>
								)}
							/>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<Controller
								name="javascript"
								control={control}
								defaultValue=""
								rules={{ required: "JavaScript code is required" }}
								render={({ field }) => (
									<>
										<OutlinedInput
											{...field}
											placeholder="JavaScript"
											fullWidth
											multiline
											rows={6}
											error={!!errors.javascript}
										/>
										{errors.javascript && (
											<FormHelperText error>
												{errors.javascript.message}
											</FormHelperText>
										)}
									</>
								)}
							/>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent={isMobile ? "center" : "flex-end"}
						>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Container>
	);
}
