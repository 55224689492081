import { useTheme } from "@emotion/react";
import { alpha, Box, Stack, Typography, useMediaQuery } from "@mui/material";

import React from "react";

function ProfileInfo({ profile }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const stats = [
    { title: "News", value: profile.news },
    { title: "Followers", value: profile.following },
    { title: "Following", value: profile.followers },
  ];
  return (
    <Stack
      direction="row"
      py={isMobile ? 2 : 5}
      spacing={2}
      width="100%"
      justifyContent={isMobile ? "space-evenly" : "space-between"}
    >
      {stats.map((stat, index) => (
        <div>
          <Typography variant="h5" textAlign="center">
            {stat.value?.toLocaleString() ?? 0}
          </Typography>
          <Typography textAlign="center">{stat.title}</Typography>
        </div>
      ))}
    </Stack>
  );
}

export default ProfileInfo;
