import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

import { usePathname } from "../../routes/hooks";
import { RouterLink } from "../../routes/components";

import { useResponsive } from "../../hooks/use-responsive";

import { account } from "../../_mock/account";

import Logo from "../../components/logo/index";
import Scrollbar from "../../components/scrollbar/index";

import { NAV } from "./config-layout";
import navConfig from "./config-navigation";
import useAuth from "src/hooks/useAuth";

import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProfileContextMenu from "./ProfileContextMenu";
// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const { user } = useAuth();

  const theme = useTheme();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <ListItemButton
      onClick={handleOpen}
      sx={{
        minHeight: 44,
        px: 4,
        py: 2,
        borderRadius: theme.shape.borderRadius / 30,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(true && {
          color: "common.white",
          fontWeight: "fontWeightSemiBold",
          bgcolor: theme.palette.grey[900],
          "&:hover": {
            bgcolor: alpha(theme.palette.grey[500], 0.16),
          },
        }),
      }}
    >
      <Avatar
        src={account.profilePic || "/assets/images/avatars/default_profile.jpg"}
        alt="photoURL"
      />

      <Box component="span">{user.name} </Box>
    </ListItemButton>
  );
  // const renderAccount = (
  //   <Button
  //     sx={{
  //       my: 3,
  //       mx: 2.5,
  //       display: "flex",
  //       borderRadius: theme.shape.borderRadius / 30,
  //       alignItems: "center",
  //       bgcolor: theme.palette.grey[900],
  //       cursor: "pointer",
  //     }}
  //     onClick={handleOpen}
  //     startIcon={
  //       <Avatar
  //         src={
  //           account.profilePic || "/assets/images/avatars/default_profile.jpg"
  //         }
  //         alt="photoURL"
  //       />
  //     }
  //   >
  //     <Box sx={{ ml: 1 }}>
  //       <Typography variant="subtitle2" color="text.primary">
  //         {user?.name}
  //       </Typography>

  //       <Typography variant="body2" sx={{ color: "text.secondary" }}>
  //         {user?.role}
  //       </Typography>
  //     </Box>
  //   </Button>
  // );

  const renderMenu = (
    <Stack component="nav" spacing={2} sx={{ px: 2 }}>
      {navConfig.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
      {/* {renderAccount} */}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Logo sx={{ mt: 3, m: 4 }} />
      <Box sx={{ flexGrow: 1 }} />

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

      <ProfileContextMenu open={open} handleClose={handleClose} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: `dashed 1px ${theme.palette.divider}`,
            background: theme.palette.common.black,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
              color: theme.palette.common.black,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  const theme = useTheme();

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        px: 4,
        py: 2,
        borderRadius: theme.shape.borderRadius / 30,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "common.white",
          fontWeight: "fontWeightSemiBold",
          bgcolor: theme.palette.grey[900],
          "&:hover": {
            bgcolor: alpha(theme.palette.grey[500], 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
