import PropTypes from "prop-types";
// /* eslint-disable unused-imports/no-unused-imports */
// /* eslint-disable no-unused-vars */
// // eslint-disable-next-line import/no-extraneous-dependencies, perfectionist/sort-named-imports, perfectionist/sort-imports

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOffAltRoundedIcon from "@mui/icons-material/ThumbDownOffAltRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";

import {
  Stack,
  useTheme,
  ButtonGroup,
  CardActions,
  CardContent,
  useMediaQuery,
  IconButton,
} from "@mui/material";

// import { fDate } from 'src/utils/format-time';

// import { HEADER } from 'src/layouts/dashboard/config-layout';
import { fDate } from "../../utils/format-time";
import { HEADER, NAV } from "../../layouts/dashboard/config-layout";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { useNavigate, useParams } from "react-router-dom";
import ArticleService from "src/services/Article.service";
import useAuth from "src/hooks/useAuth";
import DiscussionsIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import Scrollbar from "src/components/scrollbar";

// ----------------------------------------------------------------------

export default function MobFeedCard({
  article,
  onClick = () => {},
  setIsSharable = false,
  setOpen = true,
  setArticleid = "",
  setShareurl = "",
  verticleView = false,
  maxHeight,
}) {
  const {
    cover,
    title,
    body,
    createdAt,
    like,
    id,
    owner,
    isLiked,
    isDisliked,
  } = article;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { orgIdentifier } = useParams();
  const { user } = useAuth();
  const [hasLiked, setHasLiked] = useState(isLiked);
  const [hasDisliked, setHasDisliked] = useState(isDisliked);
  const handleOpen = () => {
    if (!orgIdentifier) {
      setOpen(true);
      setArticleid(id);
    }
  };
  const toggleLike = () => {
    setHasLiked((prev) => !prev);
  };
  const toggleDislike = () => {
    setHasDisliked((prev) => !prev);
  };
  const handleLike = async (user) => {
    try {
      toggleLike();
      await ArticleService.LikeArticle(user);
    } catch (error) {
      toast.error("Error in handleLike:", error);
    }
  };
  const handleDislike = async (user) => {
    try {
      toggleDislike();
      await ArticleService.DislikeArticle(user);
    } catch (error) {
      toast.error("Error in handleDislike:", error);
    }
  };
  const shareArticle = () => {
    let shareUrl = `${window.location.origin}/articles/${id}`;
    shareUrl && setShareurl(shareUrl);
    setIsSharable(true);
  };

  const renderAvatar = (
    <Avatar
      alt={owner.name}
      src={owner.profilePic}
      sx={{
        width: 25,
        height: 25,
      }}
    />
  );

  const renderAutherName = (
    <Typography
      color="inherit"
      variant="caption"
      sx={{
        overflow: "hidden",
        WebkitLineClamp: 1,
        display: "-webkit-box",
        color: "common.white",
      }}
    >
      {owner.name}
    </Typography>
  );

  const renderTitle = (
    <Typography
      color="inherit"
      variant="subtitle2"
      sx={{
        top: "0",
        position: "sticky",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        typography: "h4",
        color: "common.white",
        WebkitLineClamp: 4,
        pb: 2,
        backgroundColor: theme.palette.background.paper,
        backdropFilter: "blur(5px)",
      }}
    >
      {title}
    </Typography>
  );

  const renderBody = (
    <Typography
      color="inherit"
      variant="body2"
      fontSize={!isMobile && 18}
      sx={{
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        color: "common.white",
      }}
    >
      {body}
    </Typography>
  );

  const renderCover = (
    <Box
      id={`article-${article.id}`}
      component="img"
      alt={title}
      src={cover}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: "cover",
        position: "absolute",
        transition: theme.transitions.create(["transform"], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    />
  );

  const renderDate = (
    <Typography
      variant="caption"
      my="auto"
      sx={{
        opacity: 0.65,
        color: "common.white",
      }}
    >
      {fDate(createdAt)}
    </Typography>
  );

  return (
    <Card
      id={`article-${article.id}`}
      sx={{
        height:
          maxHeight ||
          (isMobile
            ? `calc(99dvh - ${NAV.MOBILE}px)`
            : `calc(100vh - ${HEADER.H_DESKTOP + 150}px )`),
        margin: "auto 0",
        pb: 2,
        mb: 2,
        scrollSnapAlign: isMobile ? "start" : "center",
        ...(isMobile && { borderRadius: 0 }),
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: isMobile || verticleView ? "24vh" : "100%",
          width: isMobile || verticleView ? "100%" : "45%",
          overflow: "hidden",
          cursor: "pointer",
          "&:hover > img": {
            transform: "scale(1.1)",
          },

          "&:after": {
            top: 0,
            content: "''",
            width: "100%",
            height: "100%",
            position: "absolute",
            transition: theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
            }),
            opacity: 1,
            backgroundImage: `linear-gradient(to ${
              isMobile || verticleView ? "top" : "left"
            }, ${theme.palette.background.paper} 0%, ${alpha(
              theme.palette.background.paper,
              0
            )},rgba(0, 0, 0, 0))`,
          },
          "&:hover:after": {
            opacity: 0.5,
          },
        }}
      >
        {renderCover}
      </Box>
      <CardContent
        sx={{
          p: 2.5,
          cursor: "pointer",
          pl: isMobile || verticleView ? 2.5 : "50%",
          pr: isMobile || verticleView ? 2.5 : 4,
          pt: isMobile || verticleView ? "25vh" : 4,
          height: "calc(100% - 6rem)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        onClick={onClick}
      >
        <Scrollbar>
          {renderTitle}
          {renderBody}
        </Scrollbar>
      </CardContent>

      <CardActions
        sx={{
          pr: isMobile || verticleView ? 2 : 4,
          pb: isMobile || verticleView ? 2 : 4,
          pl: isMobile || verticleView ? 2 : "50%",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          height: "7.5rem",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="animatable"
          pb={2}
        >
          <Stack direction="row" alignItems="center" spacing={1} sx={{ m: 0 }}>
            <Button
              startIcon={renderAvatar}
              onClick={() => {
                !orgIdentifier && navigate(`/profile/${owner._id}`);
              }}
            >
              {renderAutherName}
            </Button>
            <Typography sx={{ opacity: 0.7 }}>•</Typography>
            {renderDate}
          </Stack>
          <IconButton sx={{ height: 35, width: 35 }} onClick={shareArticle}>
            <ShareRoundedIcon fontSize="small" style={{ cursor: "pointer" }} />
          </IconButton>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: 1, m: 0 }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpen}
            startIcon={<DiscussionsIcon />}
            sx={{
              fontSize: "11px",
              fontWeight: "500",
              px: 2.5,
              color: "#DDDDDD",
            }}
          >
            Discussions
          </Button>

          <ButtonGroup>
            <Button
              color="primary"
              variant="contained"
              sx={{
                fontSize: "11px",
                color: hasDisliked ? "#FFFFFF" : "#DDDDDD",
              }}
              onClick={() => {
                !orgIdentifier && handleDislike(user._id);
              }}
            >
              {hasDisliked ? (
                <ThumbDownAltRoundedIcon fontSize="small" />
              ) : (
                <ThumbDownOffAltRoundedIcon fontSize="small" />
              )}
            </Button>
            <div style={{ width: "2px", height: "100%" }} />
            <Button
              color="primary"
              sx={{
                color: hasLiked ? "#FFFFFF" : "#DDDDDD",
                px: 2.5,
                fontWeight: "500",
              }}
              variant="contained"
              startIcon={
                hasLiked ? (
                  <ThumbUpAltRoundedIcon fontSize="small" />
                ) : (
                  <ThumbUpAltOutlinedIcon fontSize="small" />
                )
              }
              onClick={() => {
                !orgIdentifier && handleLike(user._id);
              }}
            >
              {like + hasLiked}
            </Button>
          </ButtonGroup>
        </Stack>
      </CardActions>
    </Card>
  );
}

MobFeedCard.propTypes = {
  article: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
