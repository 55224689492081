import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import LinkIcon from "@mui/icons-material/Link";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { medias } from "./view/share-stub";
import {
  alpha,
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function ShareView({ isOpen, setIsOpen, shareurl }) {
  const linkRef = useRef(null);
  const listRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(shareurl);
    if (linkRef.current) {
      linkRef.current.select();
      setIsCopied(true);
    }
  };
  useLayoutEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setIsCopied(false);
      }, 200);
    }
  }, [isOpen]);
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollTo = (direction) => {
    if (listRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      listRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
    handleScroll();
  };

  useEffect(() => {
    handleScroll();
    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [listRef.current]);
  return (
    <Dialog open={isOpen}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ backgroundColor: "#212121" }}
      >
        <DialogTitle sx={{ textAlign: "center", opacity: 0.8 }}>
          Share
        </DialogTitle>
        <IconButton
          aria-label="close"
          sx={{ height: 40, width: 40, mr: 1 }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />
      <Box
        sx={{
          position: "relative",
          left: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        {canScrollLeft && (
          <IconButton
            onClick={() => scrollTo("left")}
            sx={{
              position: "absolute",
              left: 0,
              zIndex: 1,
              color: alpha("#fff", 0.6),
              backgroundColor: alpha("#000", 0.5),
              "&:hover": { backgroundColor: alpha("#000", 0.7) },
            }}
          >
            <NavigateBeforeRoundedIcon />
          </IconButton>
        )}

        <List
          ref={listRef}
          sx={{
            pt: 0,
            display: "flex",
            minWidth: "500px",
            maxWidth: "500px",
            overflowX: "scroll",
            paddingY: 2,
            backgroundColor: "#212121",
            scrollbarWidth: "none",
          }}
        >
          {medias.map((media, index) => {
            const ShareComponent = media.component;
            const ShareIcon = media.icon;
            return (
              <ListItem
                disableGutters
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: 2,
                }}
              >
                <ShareComponent url={shareurl}>
                  <IconButton
                    sx={{
                      mx: 1,
                      color: media.color,
                      bgcolor: alpha(media.color, 0.3),
                    }}
                  >
                    {<ShareIcon fontSize="large" />}
                  </IconButton>
                </ShareComponent>
                <ListItemText primary={media.media} sx={{}} />
              </ListItem>
            );
          })}
        </List>
        {canScrollRight && (
          <IconButton
            onClick={() => scrollTo("right")}
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 1,
              color: alpha("#fff", 0.6),
              backgroundColor: alpha("#000", 0.5),
              "&:hover": { backgroundColor: alpha("#000", 0.7) },
            }}
          >
            <NavigateNextRoundedIcon />
          </IconButton>
        )}
      </Box>

      <Box backgroundColor="#212121" sx={{ padding: 2 }}>
        <TextField
          fullWidth
          sx={{
            borderRadius: "10px",
            color: "white",
          }}
          value={shareurl}
          autoComplete="off"
          inputRef={linkRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                onClick={handleCopyLink}
                style={{ backgroundColor: alpha("#000", 0.2) }}
              >
                {isCopied ? (
                  <DoneRoundedIcon fontSize="medium" />
                ) : (
                  <ContentCopyRoundedIcon fontSize="medium" />
                )}
              </IconButton>
            ),
          }}
        />
      </Box>
    </Dialog>
  );
}
ShareView.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Updated prop name
  setOpen: PropTypes.func.isRequired,
};
