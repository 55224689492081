import React, { useCallback, useLayoutEffect, useState } from "react";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import {
  alpha,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Avatar,
  useTheme,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
// import { faker } from "@faker-js/faker";
import ArticleService from "src/services/Article.service";

// const options = Array(15)
//   .fill(0)
//   .map((i) => ({
//     id: faker.database.mongodbObjectId(),
//     title: faker.company.name(),
//     profilePic: faker.image.avatar(),
//   }));

function SelectSources({ selections = [], setSelections }) {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchSources = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedArticles = await ArticleService.fetchArticles({
        userId: "64b6f1d254e7a15f3c1d4b7a",
      });
      const uniqueOwners = new Set();
      const newOptions = [];
      if (fetchedArticles.articles) {
        fetchedArticles.articles.forEach((article) => {
          const { owner } = article;
          if (!uniqueOwners.has(owner._id)) {
            uniqueOwners.add(owner._id);
            newOptions.push({
              id: owner._id,
              title: owner.name,
              profilePic: owner.profilePic,
            });
          }
        });
        console.log("Set: ", newOptions);
        setOptions(newOptions);
      }
    } catch (error) {
      console.log("Error in fetching news authors: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setOptions]);

  useLayoutEffect(() => {
    fetchSources();
    console.log("Sources: ", options);
  }, [setOptions]);
  const toggleSelect = (id) =>
    setSelections((prevSelections) => {
      // Check if the id already exists in the array
      const idx = prevSelections.indexOf(id);

      if (idx === -1) {
        // If the id doesn't exist, add it to the beginning of the array
        prevSelections.unshift(id);
      } else {
        // If the id exists, remove it from the array
        prevSelections.splice(idx, 1);
      }

      return [...prevSelections];
    });

  return (
    <List
      sx={{ width: "100%" }}
      subheader={<ListSubheader> Sources </ListSubheader>}
    >
      {isLoading && (
        <Box sx={{ mt: 2 }}>
          {Array(10)
            .fill(0)
            .map((skels, index) => (
              <ListItem key={index} sx={{ my: 1 }}>
                <ListItemAvatar>
                  <Skeleton
                    variant="circular"
                    width="4rem"
                    height="4rem"
                    animation="wave"
                  >
                    <Avatar />
                  </Skeleton>
                </ListItemAvatar>
                <ListItemText sx={{ ml: 3 }}>
                  <Skeleton width="20%" animation="wave">
                    <Typography variant="h6">.</Typography>
                  </Skeleton>
                </ListItemText>
              </ListItem>
            ))}
        </Box>
      )}
      {!isLoading &&
        options.map((option, index) => (
          <ListItemButton
            onClick={() => toggleSelect(option.id)}
            alignItems="flex-start"
            sx={{
              my: 1,
              fontSize: theme.typography.h1.fontSize,
              borderRadius: theme.shape.borderRadius / 30,
              color: "text.secondary",
              fontWeight: "fontWeightMedium",
              ...(selections.includes(option.id) && {
                color: "common.white",
                fontWeight: "fontWeightSemiBold",
                bgcolor: theme.palette.grey[900],
                "&:hover": {
                  bgcolor: alpha(theme.palette.grey[500], 0.16),
                },
              }),
            }}
          >
            <ListItem
              key={index}
              secondaryAction={
                selections.includes(option.id) && <CheckCircleRoundedIcon />
              }
            >
              {option.icon ? (
                <ListItemIcon>{option.icon}</ListItemIcon>
              ) : (
                <ListItemAvatar sx={{ height: "4rem", width: "4rem" }}>
                  <Avatar
                    sx={{ height: "4rem", width: "4rem" }}
                    src={option.profilePic}
                    alt={option.title}
                  />
                </ListItemAvatar>
              )}

              <ListItemText
                primary={option.title}
                sx={{
                  // height: "5rem",
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "center",
                  pl: 2,
                  fontSize: theme.typography.h1.fontSize,
                }}
              />
            </ListItem>
          </ListItemButton>
        ))}
    </List>
  );
}

export default SelectSources;
