import SpaRoundedIcon from "@mui/icons-material/SpaRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import PsychologyAltRoundedIcon from "@mui/icons-material/PsychologyAltRounded";
import BiotechRoundedIcon from "@mui/icons-material/BiotechRounded";
import SportsBaseballRoundedIcon from "@mui/icons-material/SportsBaseballRounded";
import DiamondRoundedIcon from "@mui/icons-material/DiamondRounded";
import AttractionsRoundedIcon from "@mui/icons-material/AttractionsRounded";
import { IconButton } from "@mui/material";
export const sources = [
  {
    title: "Times of India",
    id: "64d6a72e38cdf819d1b6a749",
    profilePic:
      "https://store-images.s-microsoft.com/image/apps.39382.13510798887580295.bf99df6e-cad8-48fa-9186-c564a311485f.069063b3-fa92-422c-ab02-381971393792",
  },
  {
    title: "Fox News",
    id: "64e9f13638cdf819d1b6a760",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNkvgxFSsqgR3Vd2kPV3X3vcPg_TC8bWoGeAk3RY-_8iBMy28hQJJSnjdUDiPnUeikMK8&usqp=CAU",
  },
  {
    title: "The Mirror",
    id: "64e9f05b38cdf819d1b6a75b",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCoHnrmhbe4dasWlI8A0cC3kKBnhF34JSmjvkOzwEdTTZohUeK-K6I9qBpAaJJWwR3tlY&usqp=CAU",
  },
  {
    title: "The Guardian",
    id: "64e9efc038cdf819d1b6a758",
    profilePic:
      "https://rhg.com/wp-content/uploads/2022/06/guardian-logo-square-300x300-1.jpg",
  },
  {
    title: "BBC US",
    id: "64e9f19038cdf819d1b6a762",
    profilePic:
      "https://rhg.com/wp-content/uploads/2022/06/guardian-logo-square-300x300-1.jpg", //not actual profile pic
  },
  {
    title: "Hindustan Times",
    id: "64d6a6bb38cdf819d1b6a748",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_t6sFXkSexA7tM8MKpFgjxJKxx2dLll1JGVTGAoBTpXV7Eba4cvRLhwPtBaa5dGVyL7g&usqp=CAU",
  },
];

export const categories = [
  {
    title: "Health",
    icon: (
      <IconButton>
        <SpaRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Technology",
    icon: (
      <IconButton>
        <BiotechRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Science",
    icon: (
      <IconButton>
        <PsychologyAltRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Fashion",
    icon: (
      <IconButton>
        <DiamondRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Sports",
    icon: (
      <IconButton>
        <SportsBaseballRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Business",
    icon: (
      <IconButton>
        <BusinessCenterRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Entertainment",
    icon: (
      <IconButton>
        <AttractionsRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
  {
    title: "Politics",
    icon: (
      <IconButton>
        <AttractionsRoundedIcon fontSize="large" />
      </IconButton>
    ),
  },
];
