import { Helmet } from "react-helmet-async";
import ProfilePageView from "src/sections/profile/view/profile-view";

export default function ProfilePage({ setArticleId }) {
  return (
    <>
      <Helmet>
        <title> Riple - Profile </title>
      </Helmet>

      <ProfilePageView setArticleId={setArticleId} />
    </>
  );
}
