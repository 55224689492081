import React from "react";
import { Helmet } from "react-helmet-async";
import OrgHome from "src/sections/Org/view/OrgHome";

const HomeOrg = () => {
  return (
    <>
      <Helmet>
        <title>Riple - Organization Home</title>
      </Helmet>
      <OrgHome />
    </>
  );
};

export default HomeOrg;
