// /* eslint-disable perfectionist/sort-imports */
import ThemeProvider from "./theme/index";
import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Router from "./routes/sections";
import { Toaster } from "react-hot-toast";

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ThemeProvider>
      <Toaster position="top-center" reverseOrder={false} />
      <Router />
    </ThemeProvider>
  );
}
