import { Helmet } from "react-helmet-async";
import PostAdvertisement from "../sections/advertisements/view/post-advertisement-view";

export default function AddArticlePage() {
	return (
		<>
			<Helmet>
				<title> Riple - Add Advertisement </title>
			</Helmet>
			<PostAdvertisement />
		</>
	);
}
