import PropTypes from "prop-types";
import { forwardRef } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { RouterLink } from "../../routes/components/index";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
	const logo = (
		<Box
			component="img"
			src="/assets/logo.png"
			sx={{
				width: 90,
				height: 50,
				cursor: "pointer",
				objectFit: "contain",
				objectPosition: "center",
				...sx,
			}}
		/>
	);

	if (disabledLink) {
		return logo;
	}

	return (
		<Link component={RouterLink} href="/" sx={{ display: "contents" }}>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default Logo;
