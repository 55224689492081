import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

import Nav from "./nav";
import Main from "./main";
import navConfig from "./config-navigation";
import { NAV } from "./config-layout";
import useCurrentNavIndex from "src/hooks/useCurrentPage";

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);

  const currentPageIndex = useCurrentNavIndex();

  const [value, setValue] = useState(currentPageIndex || 0);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: 1,
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        height: { xs: "100dvh", lg: "auto" },
        overflowY: { xs: "scroll", lg: "auto" },
      }}
    >
      <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

      <Main>{children}</Main>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: "block", lg: "none" },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          sx={{
            "& > .Mui-selected": {
              color: "#ffffff !important",
            },
            height: NAV.MOBILE,
          }}
          onChange={(event, navIdx) => {
            setValue(navIdx);
            navigate(navConfig[navIdx].path);
          }}
        >
          {navConfig.map((nav) => (
            <BottomNavigationAction label={nav.title} icon={nav.icon} />
          ))}

          {/* <BottomNavigationAction
            label="Profile"
            icon={<AccountCircleRoundedIcon />}
          /> */}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
