import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { HEADER } from "src/layouts/dashboard/config-layout";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
const SkeletonView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Card
        sx={{
          height: isMobile
            ? "95dvh"
            : `calc(100vh - ${HEADER.H_DESKTOP + 100}px )`,
          margin: "auto 0",
          mb: 2,
          background: "transparent",
          scrollSnapAlign: "start",
        }}
      >
        <Skeleton
          style={{
            // bgcolor: "grey.900",
            height: isMobile ? "25%" : "100%",
            width: isMobile ? "100%" : "45%",
            position: "absolute",
          }}
          variant="rectangular"
          animation="wave"
          width=""
          height={118}
        />

        <CardContent
          sx={{
            pt: 4,
            pl: isMobile ? 2 : "50%",
            pt: isMobile ? "28vh" : 4,
          }}
        >
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <Typography color="inherit" variant="subtitle2" sx={{ mb: 1 }}>
                <Skeleton
                  style={{
                    // bgcolor: "grey.900",
                    width: `${95 - index * 10}%`,
                    height: "30px",
                    WebkitLineClamp: 4,
                    color: "inherit",
                    variant: "body2",
                  }}
                  animation="wave"
                />
              </Typography>
            ))}
          <Box sx={{ mb: 5 }} />
          {Array(9)
            .fill(0)
            .map((_, index) => (
              <Typography color="inherit" variant="body2" sx={{ mb: 1 }}>
                <Skeleton
                  style={{
                    // bgcolor: "grey.900",
                    width: `${100 - index * Math.floor(Math.random() * 5)}%`,
                    height: "20px",
                    WebkitLineClamp: 4,
                    color: "inherit",
                    variant: "body2",
                  }}
                  animation="wave"
                />
              </Typography>
            ))}
          <Box sx={{ mb: 5 }} />
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap={1}
              width="50%"
            >
              <Skeleton
                animation="wave"
                variant="circular"
                width={30}
                height={30}
              />
              <Skeleton animation="wave" height={16} width="25%" />
              <Skeleton
                animation="wave"
                variant="circular"
                width={7}
                height={7}
              />
              <Skeleton animation="wave" height={16} width="35%" />
            </Box>
            <Skeleton
              animation="wave"
              height={45}
              width="8%"
              style={{ marginBottom: 6 }}
            />
          </Stack>
        </CardContent>

        <CardActions
          sx={{
            pb: 2,
            ml: isMobile ? 2 : "50%",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Skeleton
              animation="wave"
              height={36}
              variant="rectangular"
              width="22%"
              style={{
                marginBottom: 6,
                borderRadius: "20px",
              }}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              flexGrow={1}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={36}
                width={52}
                style={{
                  marginBottom: 6,
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
              />
              <div style={{ width: "2px", height: "100%" }} />
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={36}
                width={75}
                style={{
                  marginBottom: 6,
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              />
            </Box>
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default SkeletonView;
