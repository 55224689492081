import React, { useEffect, useLayoutEffect, useState } from "react";
import Logo from "src/components/logo";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useNavigate, useParams } from "react-router-dom";
import { categories, sources } from "../org-stub";
import SelectSources from "./SelectSources";
import SelectCategories from "./SelectCategories";

const OrgHome = () => {
  const theme = useTheme();
  const { orgIdentifier } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const steps = [
    {
      id: "SOURCES",
      label: "Sources",
      options: sources,
      component: (
        <SelectSources
          selections={selectedAuthors}
          setSelections={setSelectedAuthors}
        />
      ),
    },
    {
      id: "CATEGORIES",
      label: "Categories",
      options: categories,
      component: (
        <SelectCategories
          selections={selectedCategories}
          setSelections={setSelectedCategories}
        />
      ),
    },
  ];

  const maxSteps = steps.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // useLayoutEffect(() => {
  //   console.log("Selected Categories: ", selectedCategories);
  // }, [selectedCategories]);

  return (
    <Box
      sx={{
        pt: 6,
        px: { xs: 1, sm: 2, md: 4, lg: 4 },
        height: "100%",
        flexGrow: 1,
      }}
    >
      <Typography
        variant="h3"
        sx={{ textAlign: "center", verticalAlign: "center" }}
      >
        Org
        <span
          style={{
            display: "inline",
            fontSize: "1.2rem",
            fontWeight: "100",
            padding: "0 20px",
          }}
        >
          X
        </span>
        <Logo />
      </Typography>

      <Box
        sx={{
          minWidth: 400,
          maxWidth: 800,
          mx: "auto",
          mt: 6,
        }}
      >
        <MobileStepper
          variant="progress"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{ mb: 2 }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              // disabled={activeStep === maxSteps - 1}
              sx={{ color: "white" }}
            >
              {activeStep === maxSteps - 1 ? (
                <Button
                  variant="outlined"
                  // onClick={() => {
                  //   const queryParams = new URLSearchParams();
                  //   selectedIds.forEach((id) => {
                  //     queryParams.append("owner", id);
                  //   });

                  //   selectedCategories.forEach((category) => {
                  //     queryParams.append("category", category);
                  //   });
                  //   console.log("queryParams", queryParams.toString());
                  //   navigate(
                  //     `/${orgIdentifier}/feed?${queryParams.toString()}`
                  //   );
                  // }}
                  onClick={() => {
                    const ids = selectedAuthors.join(",");
                    const categories = selectedCategories.join(",");

                    navigate(
                      `/${orgIdentifier}/feed?owner=${ids}&category=${categories}`
                    );
                  }}
                  sx={{
                    background: "primary.main",
                    color: "white",
                  }}
                >
                  Read
                </Button>
              ) : (
                "Next"
              )}
              {activeStep < maxSteps - 1 && <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                color: activeStep === 0 ? "gray" : "white",
              }}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        {steps[activeStep].component}
      </Box>
    </Box>
  );
};

export default OrgHome;
