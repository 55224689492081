import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useTheme } from "@emotion/react";

function ProfileContextMenu({ open, handleClose }) {
  const theme = useTheme();
  const { user, logout } = useAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
    } catch (error) {
      console.log("Error in logging out:", error);
    } finally {
      navigate("/login");
    }
  };

  return (
    <Popover
      open={!!open}
      anchorEl={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "center", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "left" }}
      PaperProps={{
        sx: {
          width: 200,
          borderRadius: theme.shape.borderRadius / 40,
        },
      }}
    >
      <Box sx={{ my: 1.5, px: 2 }}>
        <Typography variant="subtitle1" noWrap>
          {user?.name}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          {user?.email}
        </Typography>
      </Box>

      <Divider sx={{ m: 0 }} />

      <MenuItem
        disableRipple
        disableTouchRipple
        onClick={handleLogout}
        sx={{ typography: "body2", color: "error.main", py: 1.5 }}
      >
        <ListItemIcon sx={{ color: "error.main" }}>
          <LogoutRoundedIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Popover>
  );
}

export default ProfileContextMenu;
