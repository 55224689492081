import { Helmet } from "react-helmet-async";
import ChangePasswordView from "src/sections/forgot-password/change-password-view";

export default function ChangePassword() {
  return (
    <>
      <Helmet>
        <title> Riple - Change Password </title>
      </Helmet>

      <ChangePasswordView />
    </>
  );
}
