import { useContext } from "react";
import AuthContext from "src/context/AuthContext";

const useAuth = () => {
  const auth = useContext(AuthContext);

  return { ...auth };
};

export default useAuth;
