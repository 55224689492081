import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Page404 from "src/pages/page-not-found";
import AppInitPage from "src/pages/app-init";

const ValidateOrg = (props) => {
  const hashes = ["abcd123", "cdfg536", "e5f6g7h", "ijklm89"];
  const { children } = props;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [initializing, setInitializing] = useState(false);
  const { orgIdentifier } = useParams();

  useEffect(() => {}, [orgIdentifier]);
  if (initializing) return <AppInitPage />;
  if (!hashes.includes(orgIdentifier)) return <Page404 />;
  return <>{children}</>;
};

ValidateOrg.propTypes = {
  children: PropTypes.node,
};

export default ValidateOrg;
