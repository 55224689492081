import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const EmptyView = ({ canCreate = true }) => {
  const navigate = useNavigate();
  const { orgIdentifier } = useParams();

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: 250,
          aspectRatio: 1,
          mx: "auto",
          display: "block",
          p: 2,
          py: 4,
        }}
      >
        <Box
          component="img"
          alt="no-articles"
          src="/assets/illustrations/write_article.png"
          sx={{
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "contain",
          }}
        />
      </Box>

      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          opacity: 0.5,
          letterSpacing: 1,
          m: "auto",
        }}
      >
        Nothing here right now!
        <br />
      </Typography>
      {!orgIdentifier && canCreate && (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            mt: 2,
            width: "20%",
            display: "flex",
            mx: "auto",
            py: 1.5,
            color: "#fff",
            minWidth: "150px",
          }}
          onClick={() => navigate("/add")}
        >
          Cover a story
        </Button>
      )}
    </>
  );
};

export default EmptyView;
