import { Helmet } from "react-helmet-async";

// import { LoginView } from 'src/sections/login';
import { LoginView } from "../../src/sections/login/index";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | Riple </title>
      </Helmet>

      <LoginView />
    </>
  );
}
