import toast from "react-hot-toast";
import { USER_URL } from "src/config/api.config";
import { doGet } from "src/utils/apiCallers";

export default class UserService {
  static #constructUrl(userId) {
    return USER_URL + userId;
  }

  // Main function to fetch posts
  static async fetchProfile(query) {
    try {
      const url = this.#constructUrl(query.userId);
      const result = await doGet(url, query);

      return result.data;
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
      return {};
    }
  }
}
