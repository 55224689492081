import toast from "react-hot-toast";
import { COMMENT_URL } from "src/config/api.config";
import { doGet, doPost } from "src/utils/apiCallers";

export default class CommentService {
  static async fetchComments(articleId = "") {
    const url = `${COMMENT_URL}/${articleId}`;
    try {
      const { message, data, success } = await doGet(url);
      if (!success) throw new Error(message);
      return data;
    } catch (error) {
      toast.error(error.message || error);
    }
  }
  static async createComment(commentData) {
    try {
      await doPost(
        COMMENT_URL,
        commentData,
        null,
        { "Content-Type": "application/json" },
        null
      );
      toast.success("Comment posted successfully");
      return true;
    } catch (error) {
      toast.error(error.response?.data ?? error.message);
      return false;
    }
  }
}
