import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { FeedView } from "src/sections/feed/view";

const FeedOrg = () => {
  return (
    <>
      <Helmet>
        <title>Riple - Organization Feed</title>
      </Helmet>
      <Box sx={{ pt: 4 }}>
        <FeedView />
      </Box>
    </>
  );
};

export default FeedOrg;
