import React, { useCallback, useEffect, useState } from "react";

// Custom components
import ProfileInfo from "./ProfileInfo";

// UI components
import {
  alpha,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { useTheme } from "@emotion/react";
import useAuth from "src/hooks/useAuth";

import ProfileContextMenu from "src/layouts/dashboard/ProfileContextMenu";
import UserService from "src/services/User.service";

const DEFAULT_USER_PROFILE_IMAGE = "/assets/images/avatars/default_profile.jpg";
const DEFAULT_ORG_PROFILE_IMAGE = "/assets/images/avatars/default_profile.jpg";

function ProfileHeader({ userId, newsCount }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [profileData, setProfileData] = useState({});

  const { user } = useAuth();

  const isOwnProfile = user._id === userId;

  const fetchProfile = useCallback(async () => {
    const profile = await UserService.fetchProfile({ userId });

    if (!profile.profilePic && profile.userType === "user")
      profile.profilePic = DEFAULT_USER_PROFILE_IMAGE;
    if (!profile.profilePic) profile.profilePic = DEFAULT_ORG_PROFILE_IMAGE;

    setProfileData(profile);
  }, [userId]);

  useEffect(() => {
    if (!isOwnProfile) {
      fetchProfile();
      return;
    }

    if (!user.profilePic && user.userType === "user")
      user.profilePic = DEFAULT_USER_PROFILE_IMAGE;
    else user.profilePic = DEFAULT_ORG_PROFILE_IMAGE;

    setProfileData(user);
  }, [setProfileData, ...Object.values(user), userId]);

  const [open, setOpen] = useState(null);

  const handleMenuOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOpen(null);
  };

  return (
    <Stack
      direction="column"
      justifyContent={isMobile ? "flex-end" : "center"}
      maxWidth="md"
      sx={{
        width: "100%",
        aspectRatio: isMobile ? "1" : "21/9",
        p: 4,
        pl: isMobile ? 4 : "45%",
        pb: isMobile ? 0 : 4,
        position: "relative",
        borderRadius: !isMobile && theme.shape.borderRadius / 30,
        overflow: "hidden",
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: {
            xs: "100%", // Full width on mobile
            md: "40%", // 50% width on medium screens and up
          },
          height: "100%",
        },
        "&::before": {
          backgroundImage: `url(${profileData.profilePic})`,
          zIndex: -2,
          backgroundSize: "cover",
          backgroundPosition: "center",
        },

        "&::after": {
          zIndex: -1,
          backgroundImage: `linear-gradient(to ${
            isMobile ? "top" : "left"
          }, rgba(0, 0, 0, 0.9) ,rgba(0, 0, 0, 0.1),rgba(0, 0, 0,0))`,
        },
      }}
    >
      <Typography variant="h3" textAlign={isMobile ? "center" : "start"}>
        {profileData.name}
      </Typography>
      <ProfileInfo profile={{ news: newsCount }} />
      {/* <ProfileActions profile={userId} /> */}
      {isOwnProfile && (
        <>
          <IconButton
            sx={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              backgroundColor: alpha(theme.palette.background.paper, 0.8),
              borderRadius: 0.5,
            }}
            onClick={handleMenuOpen}
          >
            <MoreVertRoundedIcon />
          </IconButton>
          <ProfileContextMenu open={open} handleClose={handleMenuClose} />
        </>
      )}
    </Stack>
  );
}

export default ProfileHeader;
