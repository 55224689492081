import { Helmet } from "react-helmet-async";
import ResetPasswordView from "src/sections/forgot-password/reset-password-view";

export default function ResetPassword() {
  return (
    <>
      <Helmet>
        <title> Riple - Reset Password </title>
      </Helmet>

      <ResetPasswordView />
    </>
  );
}
