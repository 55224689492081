import { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { bgGradient } from "../../theme/css";

import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import useAuth from "src/hooks/useAuth";
import toast from "react-hot-toast";
import { GOOGLE_AUTH_URL } from "src/config/api.config";
import { useGoogleLogin } from "@react-oauth/google";
import { doPost } from "src/utils/apiCallers";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

export default function LoginView() {
  const theme = useTheme();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = useCallback(
    async (formData, setSubmitting, reset) => {
      try {
        setSubmitting(true);
        console.log(formData.email, formData.password);
        await login(formData.email, formData.password);
        reset();
      } catch (error) {
        console.error("Error logging in:", error);
        toast.error("Failed to login. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
    [login]
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSubmit(values, setSubmitting, resetForm);
    },
  });

  const renderForm = (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          name="email"
          label="Email address"
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
          variant="subtitle2"
          underline="hover"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/reset-password")}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        disabled={formik.isSubmitting}
      >
        {formik.isSubmitting ? <CircularProgress size={24} /> : "Login"}
      </LoadingButton>
    </form>
  );
  const handleGoogleLoginSuccess = async (tokenResponse) => {
    try {
      // const res = await fetch(`${GOOGLE_AUTH_URL}/profile`, {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ accessToken: tokenResponse.access_token }),
      // });
      const result = await doPost(
        `${GOOGLE_AUTH_URL}/profile`,
        JSON.stringify({ accessToken: tokenResponse.access_token }),
        null,
        { "Content-Type": "application/json" },
        null
      );
      // console.log("Res:", res);

      console.log("Google OAuth Success:", result);

      const { success, message, data } = result;
      const userName = data.name || "User";
      await login(null, null, success, message, data);
      toast.success(`Login Success, Welcome ${userName}`);
    } catch (error) {
      console.error("Google OAuth Error:", error);
      toast.error("Failed to authenticate with Google.");
    }
  };

  // Google OAuth Hook
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: (error) => {
      console.error("Google OAuth Error:", error);
      toast.error("Failed to sign in with Google.");
    },
  });
  const handleFacebookLoginSuccess = async (response) => {
    try {
      console.log("Facebook Login Success:", response);

      const res = await fetch(
        "https://api2.riple.org/v1/auth/facebook/profile",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ accessToken: response.accessToken }),
        }
      );

      const data = await res.json();
      console.log("Response", data);
      if (data?.data) {
        const userName = data.data.name || "User";
        console.log(`Login Success, Welcome ${userName}`);
        toast.success(`Login Success, Welcome ${userName}`);
      } else {
        throw new Error("Invalid response data from the server.");
      }
    } catch (error) {
      console.error("Facebook OAuth Error:", error);
      toast.error("Failed to authenticate with Facebook. Please try again.");
    }
  };

  // Facebook Login Failure Handler
  const handleFacebookLoginFailure = (error) => {
    console.error("Facebook OAuth Error:", error);
    alert("Failed to sign in with Facebook.");
  };
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ height: 1 }}
      >
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">Sign in to Riple</Typography>

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            Don’t have an account?
            <Link variant="subtitle2" sx={{ ml: 0.5 }}>
              Get started
            </Link>
          </Typography>

          {/* 
          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              onClick={googleLogin}
            >
              <Iconify icon="eva:google-fill" color="#DF3E30" />
            </Button>
            <FacebookLogin
              appId="446276294863695"
              callback={handleFacebookLoginSuccess}
              onFailure={handleFacebookLoginFailure}
              render={(renderProps) => (
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
                  onClick={renderProps.onClick}
                >
                  <Iconify icon="eva:facebook-fill" color="#1877F2" />
                </Button>
              )}
            />
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:apple-fill" color="#1877F2" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:linkedin-fill" color="#1C9CEA" />
            </Button>
          </Stack> */}

          {/* <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider> */}

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}
