import React, { useState } from "react";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import {
  alpha,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Avatar,
  useTheme,
  Grid,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import { faker } from "@faker-js/faker";

const options = Array(15)
  .fill(0)
  .map((i) => ({
    id: faker.database.mongodbObjectId(),
    title: faker.company.name(),
    profilePic: faker.image.avatar(),
  }));

function SelectCategories({ selections = [], setSelections }) {
  const theme = useTheme();
  //  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toggleSelect = (id) =>
    setSelections((prevSelections) => {
      // Check if the id already exists in the array
      const idx = prevSelections.indexOf(id);

      if (idx === -1) {
        // If the id doesn't exist, add it to the beginning of the array
        prevSelections.unshift(id);
      } else {
        // If the id exists, remove it from the array
        prevSelections.splice(idx, 1);
      }

      return [...prevSelections];
    });

  return (
    <>
      <List>
        <ListSubheader> Categories </ListSubheader>
      </List>{" "}
      <Grid container spacing={1}>
        {isLoading &&
          Array(10)
            .fill(0)
            .map((skels, index) => (
              <Grid item md={6} sm={12} xs={12}>
                <ListItem key={index} sx={{ my: 1 }}>
                  <ListItemAvatar>
                    <Skeleton
                      variant="circular"
                      width="4rem"
                      height="4rem"
                      animation="wave"
                    >
                      <Avatar />
                    </Skeleton>
                  </ListItemAvatar>
                  <ListItemText sx={{ ml: 3 }}>
                    <Skeleton width="100%" animation="wave">
                      <Typography variant="h6">.</Typography>
                    </Skeleton>
                  </ListItemText>
                </ListItem>
              </Grid>
            ))}
        {!isLoading &&
          options.map((option, index) => (
            <Grid item md={6} sm={12} xs={12}>
              <Box>
                <ListItemButton
                  onClick={() => toggleSelect(option.id)}
                  sx={{
                    my: 1,
                    fontSize: theme.typography.h1.fontSize,
                    borderRadius: theme.shape.borderRadius / 30,
                    color: "text.secondary",
                    fontWeight: "fontWeightMedium",
                    ...(selections.includes(option.id) && {
                      color: "common.white",
                      fontWeight: "fontWeightSemiBold",
                      bgcolor: theme.palette.grey[900],
                      "&:hover": {
                        bgcolor: alpha(theme.palette.grey[500], 0.16),
                      },
                    }),
                  }}
                >
                  <ListItem
                    key={index}
                    secondaryAction={
                      selections.includes(option.id) && (
                        <CheckCircleRoundedIcon />
                      )
                    }
                  >
                    {option.icon ? (
                      <ListItemIcon>{option.icon}</ListItemIcon>
                    ) : (
                      <ListItemAvatar sx={{ height: "4rem", width: "4rem" }}>
                        <Avatar
                          sx={{ height: "4rem", width: "4rem" }}
                          src={option.profilePic}
                          alt={option.title}
                        />
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary={option.title}
                      sx={{
                        pl: 2,
                        fontSize: theme.typography.h1.fontSize,
                      }}
                    />
                  </ListItem>
                </ListItemButton>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default SelectCategories;
