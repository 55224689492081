import { useLocation } from "react-router-dom";
import navConfig from "src/layouts/dashboard/config-navigation";

const useCurrentNavIndex = () => {
  const location = useLocation(); // Get the current location from React Router
  const currentPath = location.pathname; // Get the current path

  // Find the index of the path that matches the current path
  const index = navConfig.findIndex((nav) => nav.path === currentPath);

  return index;
};

export default useCurrentNavIndex;
