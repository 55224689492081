import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import LoginPage from "src/pages/login";
import AppInitPage from "src/pages/app-init";

const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const sessionKey = JSON.parse(localStorage.getItem("sessionKey")) || null;
    sessionKey && setAccessToken(sessionKey.accessToken);
    // console.log("accessToken", accessToken);
  }, [accessToken]);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    if (accessToken === "") return <LoginPage />;
    else return <AppInitPage />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
