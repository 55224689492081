import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";

export const medias = [
  {
    media: "Facebook",
    icon: FacebookRoundedIcon,
    color: "#1877f2",
    component: FacebookShareButton,
  },
  {
    media: "Whatsapp",
    icon: WhatsAppIcon,
    color: "#25d366",
    component: WhatsappShareButton,
  },
  {
    media: "Linkedin",
    icon: LinkedInIcon,
    color: "#0077b5",
    component: LinkedinShareButton,
  },
  {
    media: "Twitter",
    icon: XIcon,
    color: "#1da1f2",
    component: TwitterShareButton,
  },
  {
    media: "Telegram",
    icon: TelegramIcon,
    color: "#24A1DE",
    component: TelegramShareButton,
  },
  {
    media: "Gmail",
    icon: MailRoundedIcon,
    color: "#3e65cf",
    component: EmailShareButton,
  },
];
