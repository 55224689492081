import { Box, styled, Avatar, LinearProgress } from "@mui/material";

const LogoImage = styled(Avatar)(
  () => `
        object-fit: contain;
        width: 100px;
        height: 100px;
        background-image: transparent;
`
);

export default function AppInitView() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        bgcolor: "black",
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {/* <LogoImage src="/assets/gifs/riple.gif" alt="Riple" /> */}
      <LogoImage
        src="/assets/logo_mark.svg"
        alt="Riple"
        sx={{ opacity: 0.7 }}
      />
      <Box sx={{ width: "150px", maxWidth: "50%", my: 2 }}>
        <LinearProgress sx={{ height: 5, borderRadius: 8 }} color="primary" />
      </Box>
    </Box>
  );
}
