import { Box, Tabs, Tab, useMediaQuery, Container } from "@mui/material";

import { useCallback, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// import profileData from "./profile.stub";

import discussionsData from "./discussion-stub";
import ProfileHeader from "../_components/ProfileHeader";

import ArticleService from "src/services/Article.service";
import { useParams } from "react-router-dom";
import PaginationView from "../profile-components/PaginationView";
import RenderNews from "../profile-components/RenderNews";
import RenderDiscussions from "../profile-components/RenderDiscussions";
import useAuth from "src/hooks/useAuth";
import { EmptyView } from "src/sections/feed/view";
import { useTheme } from "@emotion/react";

export default function ProfilePageView() {
  const [tabValue, setTabValue] = useState(0);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let { ownerid } = useParams();
  const tabs = ["News", "Discussions"];
  const handleTabChange = (event, newValue) => setTabValue(newValue);
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totaldiscussions, setTotalDiscussions] = useState(0);
  const [profileData, setProfileData] = useState({});
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchArticles = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedArticles = await ArticleService.fetchArticles({
        owners: ownerid || user._id,
        userId: "64b6f1d254e7a15f3c1d4b7a",
        page: page,
        limit: isMobile ? 10 : 30,
      });

      if (fetchedArticles) {
        setArticles(fetchedArticles.articles);
        setTotalPages(fetchedArticles.totalPages);
        // console.log("news: ", fetchedArticles.articles);
        // console.log("total articles: ", fetchedArticles.totalArticles);
        // console.log("total pages: ", fetchedArticles.totalPages);
        const profile = {
          username: "",
          ownerid: ownerid || user?._id,
          name: ownerid ? fetchedArticles.articles[0].owner.name : user?.name,
          avatar: ownerid
            ? fetchedArticles.articles[0].owner.profilePic
            : "/assets/images/avatars/default_profile.jpg",
          followers: 0,
          following: 0,
          newsCount: ownerid ? fetchedArticles.totalArticles : 0,
        };
        setProfileData(profile);
      }
    } catch (error) {
      console.log("Error in fetching articles in profile-view page: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [ownerid, page, isMobile]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useLayoutEffect(() => {
    fetchArticles();
  }, [page, isMobile, ownerid]);

  return (
    <Container
      sx={{
        ...(isMobile ? { pl: 0, pr: 0 } : {}),
        height: "100%",
      }}
    >
      <ProfileHeader
        userId={ownerid || user?._id}
        newsCount={profileData.newsCount}
      />

      <EmptyView canCreate={!ownerid} />
    </Container>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
