import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.location.href = "https://riple.org/terms.html";
  }, []);
  return null;
};

export default Terms;
