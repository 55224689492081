import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.location.href = "https://riple.org/privacy-policy.html";
  }, []);
  return null;
};

export default PrivacyPolicy;
